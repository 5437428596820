.layout-wrap { 
	@include respond-to("desktop") { margin: 0 auto; min-width: 1024px; max-width: 1400px; }
}

.layout-header { display: flex; flex-direction: column; justify-content: flex-start; box-sizing: border-box; padding: 39px 22px; height: 151px;
	.logo { overflow: hidden; display: inline-block; box-sizing: border-box; height: 31px; width: 139px; text-indent: -9999px; background: inline-svg("logo-03", #000) center center no-repeat; background-size: 100%; }
	.admin { display: inline-block; margin: 0 0 0 10px; font-size: rem(20); font-weight: 700; color: #000; line-height: em(31, 20); vertical-align: middle; text-transform: uppercase; }

	.actions { margin-top: 10px; }

	@include respond-to("desktop") { flex-direction: row; justify-content: flex-end; }

	&.admin {
		@include respond-to("desktop") { justify-content: space-between; }
	}
}


.layout-footer { position: relative; display: block; box-sizing: border-box; padding: 128px 28px 19px 28px; border-top: 2px solid #D8D8D8;
	@include respond-to("desktop") { padding: 179px 28px 27px 28px; }

	.info { display: block;
		&::after { content: ''; clear: both; display: table; }

		.item { float: left; display: block;
			&.socials { width: 120px; }
			&.address { clear: both; margin-top: 34px; }
		}
		dt { display: block; font-size: rem(12); font-weight: 700; color: #000000; line-height: em(17, 12); }
		dd { display: block; margin-top: 17px; font-size: rem(12); font-weight: 400; color: #000000; line-height: em(17, 12);
			a { font-size: rem(12); font-weight: 400; color: #000000; line-height: em(17, 12); }
		}


		@include respond-to("desktop") { position: absolute; top: auto; right: 28px; bottom: 27px; left: auto; display: flex;
			&::after { content: none; }

			.item { float: none; min-width: 86px;
				+ .item { margin-left: 59px; }

				&.socials { width: auto; }
				&.address { margin-top: 0; }
			}
		}
	}

	.copyright { display: block; margin-top: 45px; font-size: rem(12); font-weight: 400; color: #000000; line-height: em(17, 12);
		@include respond-to("desktop") { margin: 0; }
	}
}



.layout-contents-container { display: block; box-sizing: border-box;
	.page-title { display: block; box-sizing: border-box; font-size: rem(15); font-weight: 500; color: #000; text-align: center; line-height: em(20, 15); }
}


.layout-headless-container { display: flex; flex-direction: column; justify-content: center; box-sizing: border-box; padding: 0 22px; min-height: 100vh; background-color: #fff;
	.logo { overflow: hidden; display: block; box-sizing: border-box; margin: 0 auto; height: 31px; width: 139px; text-indent: -9999px; background: inline-svg("logo-03", #000) center center no-repeat; background-size: 100%; }
}